<template>
  <client-only>
    <JoszakiAutocompleteInner v-bind="$attrs" v-on="$listeners" />
  </client-only>
</template>

<script>
import JoszakiAutocompleteInner from "./JoszakiAutocompleteInner.vue";

export default {
  components: { JoszakiAutocompleteInner },
  inheritAttrs: false,
};
</script>
